import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { MomentModule } from 'ngx-moment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {RecaptchaModule} from 'ng-recaptcha';
import {FormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {GALLERY_CONFIG, GalleryModule} from 'ng-gallery';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {HomeComponent} from './pages/home/home.component';
import {SliderComponent} from './components/slider/slider.component';
import {SliderNewsComponent} from './components/slider-news/slider.component';
import {ServicesComponent} from './components/services/services.component';
import {TestimonialsComponent} from './components/testimonials/testimonials.component';
import {NewsHomeComponent} from './components/news/news.component';
import {HomeMapComponent} from './components/home-map/home-map.component';
import {FooterComponent} from './components/footer/footer.component';
import {DinamicContentComponent} from './pages/dinamic-content/dinamic-content.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {MutaryComponent} from './pages/mutary/mutary.component';
import {NewsComponent} from './pages/news/news.component';
import {NewsListTemplateComponent} from './components/news-list-template/news-list-template.component';
import {CorridorListTemplateComponent} from './components/corridor-list-template/corridor-list-template.component';
import {NewsDetailsComponent} from './pages/news-details/news-details.component';
import {CallToActionComponent} from './components/call-to-action/call-to-action.component';
import {ProjectsComponent} from './pages/projects/projects.component';
import {SocialResponsibilitiesComponent} from './pages/social-responsibilities/social-responsibilities.component';
import {PortPositionComponent} from './pages/port-position/port-position.component';
import {ContactsComponent} from './pages/contacts/contacts.component';
import {ShippingLinesComponent} from './pages/shipping-lines/shipping-lines.component';
import {TariffsComponent} from './pages/tariffs/tariffs.component';
import {PublicationsComponent} from './pages/publications/publications.component';
import {GalleryComponent} from './pages/gallery/gallery.component';
import {CorridorInitiativeComponent} from './pages/corridor-initiative/corridor-initiative.component';
import {SocialListTemplateComponent} from './components/social-list-template/social-list-template.component';
import {ProjectsDetailsComponent} from './pages/projects-details/projects-details.component';
import {CorridorInitiativeDetailsComponent} from './pages/corridor-initiative-details/corridor-initiative-details.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MagazineComponent} from './pages/magazine/magazine.component';
import {BerthPlanComponent} from './pages/berth-plan/berth-plan.component';
import { ComplaintFormComponent } from './pages/complaint-form/complaint-form.component';
import { ComplaintPDFComponent } from './pages/complaint-pdf/complaint-pdf.component';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent,
        SliderComponent,
        SliderNewsComponent,
        ServicesComponent,
        TestimonialsComponent,
        NewsHomeComponent,
        HomeMapComponent,
        FooterComponent,
        DinamicContentComponent,
        AboutUsComponent,
        MutaryComponent,
        NewsComponent,
        NewsListTemplateComponent,
        CorridorListTemplateComponent,
        NewsDetailsComponent,
        CallToActionComponent,
        ProjectsComponent,
        SocialResponsibilitiesComponent,
        PortPositionComponent,
        ContactsComponent,
        ShippingLinesComponent,
        TariffsComponent,
        PublicationsComponent,
        GalleryComponent,
        CorridorInitiativeComponent,
        SocialListTemplateComponent,
        ProjectsDetailsComponent,
        CorridorInitiativeDetailsComponent,
        MagazineComponent,
        BerthPlanComponent,
        ComplaintFormComponent,
        ComplaintPDFComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MomentModule,
        RecaptchaModule,
        FormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        GalleryModule,
        CommonModule,
        RouterModule,
        PdfViewerModule,
    ],
    providers: [
        {
            provide: GALLERY_CONFIG,
            useValue: {
                dots: true,
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
