<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1>{{ area_name[lang] }}</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a>{{ area_name[lang] }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Section Start -->
<div class="project-page-sec pt-100 pb-100">
  <div class="container">
    <!-- Section Title Start -->
    <div class="row">
      <div class="col-md-12">
        <div class="sec-title">
          <h1>{{ area_title[lang] }}</h1>
          <p class="text-justify" [innerHtml]="area_description[lang]"></p>
          <div class="mt-20" [innerHtml]="area_description2[lang]"></div>
        </div>
      </div>
    </div>
    <!-- Section Title End -->
    <div class="row">
      <div class="col-md-12" *ngFor="let cat of cats">
        <h1><a>{{ cat.cat ? cat.cat.name[lang] : 'Documents' | translate }}</a></h1>
        <div class="service-sidebar">
          <div class="service-cat">
            <ul>
              <li *ngFor="let doc of cat.docs"><a [href]="host + '/documents/' + doc.filename" target="_blank">{{ doc.name[lang] }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
