<!-- Footer Section Start -->
<footer class="footer">
  <!-- Footer Top Start -->
  <div class="container">
    <div class="row pd-top-10">
      <div class="slider-container">
        <div class="splide" role="group" aria-label="Splide Basic HTML Example">
          <div class="splide__track">
            <ul class="splide__list">
              <li class="splide__slide" *ngFor="let mag of ($magazines | async)">
                <a (click)="openMagazine(mag)">
                  <img [src]="host + '/storage/magazines/thumbs/' + mag.image" style="width:100px;">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-sec">
    <div class="container">
      <div class="row">
        <!-- Single Footer Widget Start -->
        <div class="col-md-5 col-sm-6">
          <div class="footer-wedget-one">
            <a href="index.html" class="footer-logo"><img src="assets/img/footer-logo.png" alt=""/></a>
            <p translate>The shortest, quickest & safest route to the sea for the southern Africa hinterland.</p>
            <div class="footer-social">
              <ul>
                <li>
                  <a href="https://www.instagram.com/corneldermoz" target="_blank">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/CornelderMoz" target="_blank">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UC-stYrp6PUBiHjMRS3nJj7g" target="_blank">
                    <i class="fa fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/cornelder-de-mocambique/" target="_blank">
                    <i class="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Single Footer Widget End -->
        <!-- Single Footer Widget Start -->
        <div class="col-md-4 col-sm-6">
          <div class="footer-widget-menu">
            <h2 translate>our solutions</h2>
            <ul>
              <li><a [routerLink]="['/port/container-terminal']" translate>Container Terminal</a></li>
              <li><a [routerLink]="['/port/general-cargo']" translate>General Cargo Terminal</a></li>
              <li><a [routerLink]="['/port/cargo-terminal']" translate>Cargo Handling Equipment</a></li>
              <li><a [routerLink]="['/port/grain-terminal']" translate>Beira Grain Terminal</a></li>
              <li><a [routerLink]="['/port/safety']" translate>Safety</a></li>
            </ul>
          </div>
        </div>
        <!-- Single Footer Widget End -->
        <!-- Single Footer Widget Start -->
        <div class="col-md-3 col-sm-6">
          <div class="footer-widget-menu">
            <h2 translate>useful links</h2>
            <ul>
              <li><a [routerLink]="['/about-us']" translate>About Us</a></li>
              <li><a [routerLink]="['/port/container-terminal']" translate>Port_facilities</a></li>
              <li><a [routerLink]="['/projects']" translate>Projects</a></li>
              <li><a [routerLink]="['/news']" translate>News</a></li>
              <li><a [routerLink]="['/social-responsibilities']" translate>Social_Responsibilities</a></li>
              <li><a [routerLink]="['/port-position']" translate>Port Position</a></li>
              <li><a [routerLink]="['/contacts']" translate>Contact Us</a></li>
            </ul>
          </div>
        </div>
        <!-- Single Footer Widget End -->
      </div>
    </div>
  </div>
  <!-- Footer Top End -->
  <!-- Footer Bottom Start -->
  <div class="footer-bottom-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-12">
          <div class="copy-right">
            <span>Copyright &copy; {{ moment().year() }} Cornelder. {{ 'All rights reserved.' | translate }} </span>
          </div>
        </div>
        <!-- <div class="col-md-4 col-sm-12">
          <div class="site-developer">
            <span translate>Developed by <a href="http://veludoementol.co.mz">Veludo&Mentol</a></span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- Footer Bottom End -->
</footer>
<!-- Footer Section End -->
