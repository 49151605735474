import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ImageItem} from "ng-gallery";

declare const $: any;

@Component({
    selector: 'app-news-details',
    templateUrl: './news-details.component.html',
    styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
    host = '';
    lang = 'en';
    id = '';
    news: any;
    latest = [];
    photos = [];

    constructor(private api: ApiService, private route: ActivatedRoute, private router: Router) {

        this.host = this.api.getHost();
        this.lang = this.api.loadPrefLang();

        this.route.paramMap.subscribe(params => {
            this.id = params.get('news_info').split('_')[0];
            if (this.id) {
                this.api.getNewsById(this.id).subscribe((news: any) => {
                    this.news = news;
                    if (this.news.photo) {
                        this.photos.push(new ImageItem({
                            src: this.host + '/storage/news/' + this.news.photo,
                            thumb: this.host + '/storage/news/' + this.news.photo
                        }));
                    }
                    this.news.photos.forEach(f => {
                      this.photos.push(new ImageItem({
                          src: this.host + '/storage/news/' + f.photo,
                          thumb: this.host + '/storage/news/' + f.photo
                      }));
                    });
                });
            }
        });
    }

    ngOnInit() {
        // setTimeout(() => {
        //     $('#main-menu > ul > li.active').removeClass('active');
        //     $($('#main-menu > ul > li')[2]).addClass('active');
        // }, 500);

        this.api.latestNews().subscribe((data: any) => {
            this.latest = data;
        });
    }

    goToNewsDetails(news: any) {
        this.router.navigate([`/news/${news.id}_${encodeURIComponent(news.title[this.lang].split(' ').join('-'))}`]).then(() => {
            console.log('going to news details');
        });
    }

    getShareName() {
        return encodeURIComponent(this.news.title[this.lang]);
    }

    getShareURL(news) {
        return `https://cornelder.mz.co/news/${news.id}_${encodeURIComponent(news.title[this.lang].split(' ').join('-'))}`;
    }
}
