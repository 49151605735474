<!-- Service Section Start -->
<div class="service-sec pt-100">
  <div class="container">
    <!-- Section Title Start -->
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="sec-title">
          <h1 translate>Port of Beira - CORNELDER de Moçambique</h1>
        </div>
      </div>
    </div>
    <!-- Section Title End -->

    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="sec-title">
          <h2 translate>Road distances in Km</h2>
          <div class="border-bottom"></div>
          <div [innerHTML]="this.content['table']?.description[lang]"></div>
        </div>
      </div>
    </div>
    <div class="row mt-15">
      <div class="col-md-12 col-xs-12">
        <div class="sec-title">
          <h2 translate>Our location</h2>
          <div class="border-bottom"></div>
          <p><img src="assets/img/map.png"></p>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Single Service Inner Start -->
      <div class="col-md-12 col-sm-12 col-xs-12 inner">
        <div class="single-service-inner no-bottom">
          <div class="service-title-inner">
            <div class="media">
              <div class="media-body">
                <div class="service-title text-center">
                  <h2><a>{{ content['content_1'] ? content['content_1'].name[lang] : '' }}</a></h2>
                  <span translate>{{ content['content_1'] ? content['content_1'].title[lang] : '' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div [innerHTML]="content['content_1'] ? content['content_1'].description[lang] : ''">
          </div>
          <ul *ngIf="content['content_1']">
            <li class="top42" *ngFor="let line of splitContent(content['content_1'].description2[lang])">
              {{ line }};
            </li>
          </ul>
        </div>
      </div>
      <!-- Single Service Inner End -->
      <!-- Single Service Inner Start -->
      <div class="col-md-4 col-sm-6 col-xs-12 inner">
        <div class="single-service-inner no-bottom">
          <div class="service-thumb" *ngIf="getPhoto('container-terminal')">
            <img [src]="host + getPhoto('container-terminal')" alt=""/>
          </div>
          <div class="service-title-inner">
            <div class="media">
              <div class="media-left">
                <div class="service-icon">
                  <img src="assets/img/icon/container.png" alt=""/>
                </div>
              </div>
              <div class="media-body">
                <div class="service-title">
                  <h2><a>{{ content['content_2'] ? content['content_2'].name[lang] : '' }}</a></h2>
                  <span>{{ content['content_2'] ? content['content_2'].title[lang] : '' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="small" [innerHTML]="content['content_2'] ? content['content_2'].description[lang] : ''">
          </div>
        </div>
      </div>
      <div class="col-xs-12 xs-show inner text-center">
        <a class="btn" translate [routerLink]="['/port/container-terminal']">Click to open</a>
      </div>
      <!-- Single Service Inner End -->
      <!-- Single Service Inner Start -->
      <div class="col-md-4 col-sm-6 col-xs-12 inner">
        <div class="single-service-inner no-bottom">
          <div class="service-thumb" *ngIf="getPhoto('general-cargo')">
            <img [src]="host + getPhoto('general-cargo')" alt=""/>
          </div>
          <div class="service-title-inner">
            <div class="media">
              <div class="media-left">
                <div class="service-icon">
                  <img src="assets/img/icon/cargo.png" alt=""/>
                </div>
              </div>
              <div class="media-body">
                <div class="service-title">
                  <h2><a>{{ content['content_3'] ? content['content_3'].name[lang] : '' }}</a></h2>
                  <span translate>{{ content['content_3'] ? content['content_3'].title[lang] : '' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="small" [innerHTML]="content['content_3'] ? content['content_3'].description[lang] : ''">
          </div>
        </div>
      </div>
      <div class="col-xs-12 xs-show inner text-center">
        <a class="btn" translate [routerLink]="['/port/general-cargo']">Click to open</a>
      </div>
      <!-- Single Service Inner End -->
      <!-- Single Service Inner Start -->
      <div class="col-md-4 col-sm-6 col-xs-12 inner" *ngIf="false">
        <div class="single-service-inner">
          <div class="service-thumb">
            <img src="assets/img/s3.jpg" alt=""/>
          </div>
          <div class="service-title-inner">
            <div class="media">
              <div class="media-left">
                <div class="service-icon">
                  <img src="assets/img/icon/vechicle.png" alt=""/>
                </div>
              </div>
              <div class="media-body">
                <div class="service-title">
                  <h2><a>Cargo handling equipment</a></h2>
                  <span translate>All equipment for all kind of cargo</span>
                </div>
              </div>
            </div>
          </div>
          <ul>
            <li class="small" translate>Ship to Shore Gantry cranes available with 50 tons under hook lifting capacity each;</li>
            <li class="small" translate>Rail Mounted Gantry crane available with 50 tons for loading and offloading of wagons;</li>
            <li class="small" translate>Reachstackers - 45 tons;</li>
            <li class="small" translate>Kalmar Forklifts - 16 - 45 tons;</li>
            <li class="small" translate>Terminal tractors with 60 tons capacity and four wheel drive;</li>
            <li class="small" translate>Payloaders for multiuse;</li>
            <li class="small" translate>Shunting tractors;</li>
            <li class="small" translate>Mobile cranes - 35 and 45 tons;</li>
            <li class="small" translate>Normal trailers and skeletons;</li>
          </ul>
        </div>
      </div>
      <!-- Single Service Inner End -->
      <!-- Single Service Inner Start -->
      <div class="col-md-4 col-sm-6 col-xs-12 inner">
        <div class="single-service-inner no-bottom">
          <div class="service-thumb" *ngIf="getPhoto('beans')">
            <img [src]="host + getPhoto('beans')" alt=""/>
          </div>
          <div class="service-title-inner">
            <div class="media">
              <div class="media-left">
                <div class="service-icon">
                  <img src="assets/img/icon/grain.png" alt=""/>
                </div>
              </div>
              <div class="media-body">
                <div class="service-title">
                  <h2><a>{{ content['content_4'] ? content['content_4'].name[lang] : '' }}</a></h2>
                  <span>{{ content['content_4'] ? content['content_4'].title[lang] : '' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="small" [innerHTML]="content['content_4'] ? content['content_4'].description[lang] : ''">
          </div>
          <ul *ngIf="content['content_4']">
            <li class="small" *ngFor="let line of splitContent(content['content_4'].description2[lang])">
              {{ line }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12 xs-show inner text-center">
        <a class="btn" translate [routerLink]="['/port/grain-terminal']">Click to open</a>
      </div>
      <!-- Single Service Inner End -->
      <!-- Single Service Inner Start -->
      <div class="col-md-4 col-sm-6 col-xs-12 inner" *ngIf="false">
        <div class="single-service-inner no-bottom">
          <div class="service-thumb">
            <img src="assets/img/s5.jpg" alt=""/>
          </div>
          <div class="service-title-inner">
            <div class="media">
              <div class="media-left">
                <div class="service-icon">
                  <img src="assets/img/icon/shield.png" alt=""/>
                </div>
              </div>
              <div class="media-body">
                <div class="service-title">
                  <h2><a href="#" translate>Other information</a></h2>
                  <span translate>Port security</span>
                </div>
              </div>
            </div>
          </div>
          <p class="small">
            {{ 'Port of Beira is recognized as one of the regional leading port in safety and security.' | translate }}<br>
            {{ 'Cutting-edge technology and progressive procedure are in place that provide high levels of protection and, at the same time, support compliance with port business policies.' | translate }}<br>
            {{ 'The terminals are fully secured with a hardened perimeter fence; a wide CCTV security camera sustem installed and has a Bio-Metric Access control, visitors and cargo, which is done by dedicated Security Company Specialized in Port Security.' | translate }}
          </p>
        </div>
      </div>
      <!-- Single Service Inner End -->
    </div>
    <div class="row" style="margin-bottom: 25px;">
      <div class="col-md-4 col-sm-6 xs-hidden inner text-center">
        <a class="btn" translate [routerLink]="['/port/container-terminal']">Click to open</a>
      </div>
      <div class="col-md-4 col-sm-6 xs-hidden inner text-center">
        <a class="btn" translate [routerLink]="['/port/general-cargo']">Click to open</a>
      </div>
      <div class="col-md-4 col-sm-6 xs-hidden inner text-center">
        <a class="btn" translate [routerLink]="['/port/grain-terminal']">Click to open</a>
      </div>
    </div>
  </div>
</div>
<!-- Service Section End -->
