<div class="single-post" (click)="goToCorridorDetails()">
  <div class="single-post-thumb">
    <img *ngIf="item.photo" [src]="host + '/beiracorridor/' + item.photo" alt=""/>
    <div class="single-post-thumb-overlay">
      <div class="post-meta" *ngIf="false">
        <span class="post-date">{{ item.created_at | amLocale:lang | amDateFormat:'DD' }}</span>
        <span class="post-month">{{ item.created_at | amLocale:lang | amDateFormat:'MMM' }}</span>
      </div>
    </div>
  </div>
  <div class="single-post-text">
    <div class="post-meta-info">
      <h2><a>{{ item.title[lang] }}</a></h2>
      <div class="post-meta-data">
        <ul>
          <li *ngIf="item.link"><a target="_blank" href="{{ item.link }}"><i class="fa fa-link"></i>{{ item.link }}</a></li>
        </ul>
      </div>
    </div>
    <p *ngIf="!HideContent">{{ item.summary[lang] }}</p>
  </div>
</div>
