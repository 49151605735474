import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

declare const $: any;

@Component({
  selector: 'app-magazine',
  templateUrl: './magazine.component.html',
  styleUrls: ['./magazine.component.scss']
})
export class MagazineComponent implements OnInit {
  id = '';
  $magazine = new BehaviorSubject(null);
  magazine = null;

  constructor(
      private service: ApiService,
      private route: ActivatedRoute,
      private sanitizer: DomSanitizer,
  ) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.loadContent();
    });
  }

  ngOnInit() {
  }

  loadContent() {
    this.service.loadMagazine(this.id).subscribe((data: any) => {
      this.$magazine.next(data);
      this.magazine = this.sanitizer.bypassSecurityTrustResourceUrl(data.link);
    });
  }

}
