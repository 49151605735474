import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../../services/api.service';

declare const $: any

@Component({
  selector: 'app-dinamic-content',
  templateUrl: './dinamic-content.component.html',
  styleUrls: ['./dinamic-content.component.scss']
})
export class DinamicContentComponent implements OnInit {
  area_name = ''
  area_title = ''
  area_description = ''
  area_description2 = ''
  lang = 'en'
  host = ''
  photos = []
  area = null
  areas = [{
    title: 'Container Terminal',
    link: 'container-terminal'
  }, {
    title: 'General Cargo Terminal',
    link: '/port/general-cargo'
  }, {
    title: 'Beira Grain Terminal',
    link: '/port/grain-terminal'
  }]

  constructor(private api: ApiService, private route: ActivatedRoute) {
    this.lang = this.api.loadPrefLang()
    this.host = this.api.getHost()

    this.route.paramMap.subscribe(params => {
      this.area = params.get("area");
      if (this.area) {
        this.loadContent('port', this.area);
      } else {
        this.loadContent('port', 'safety');
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.area) {
        $('.pagehding-sec, .project-page-sec').addClass(this.area);
      } else {
        $('.pagehding-sec, .project-page-sec').addClass('safety');
      }
    }, 500);
  }

  loadContent(area: string, subarea: string) {
    this.api.loadContent(area, subarea).subscribe((data: any) => {
      this.area_name = data.name
      this.area_title = data.title
      this.area_description = data.description
      this.area_description2 = data.description2
      $('.pagehding-sec').attr('class', '').addClass('pagehding-sec ' + subarea);
      $('.project-page-sec').attr('class', '').addClass('project-page-sec ' + subarea);
      // $('#loader').fadeOut(500)
    })
    this.api.loadGallery(area, subarea).subscribe((data: any) => {
      this.photos = data
      setTimeout(() => {
        $(document).ready(function() {
            $('.fancybox').fancybox();
        });
      }, 500)
    })
  }

  getDummyArray(photos) {
    const s = photos ? photos.length : 0
    if (s === 0 || s > 2) {
      return [];
    } else if (s === 1 || s === 2) {
      return Array.from(Array(1).keys())
    }
  }
}
