<!-- Slider Section Start -->
<div class="slider" *ngIf="news">
  <div class="all-slide2 owl-item">
    <!-- Slider Single Item Start -->
    <div class="single-slide" *ngFor="let n of news" (click)="goToNews(n)">

      <div class="slider-wrapper">
        <div class="slider-text2">
          <div class="slider-caption">
            <h1>{{ n.title[lang] }}</h1>
          </div>
        </div>
        <div class="slide-news-picture" [ngStyle]="{'background-image': 'url(' + host + '/storage/news/' + (n.photos.length > 0 ? n.photos[0].photo : n.photo) + ')' }">
        </div>
      </div>
    </div>
    <!-- Slider Single Item End -->
  </div>
  <div class="clickme">
    <div>
      <span translate>
        click for more
      </span>
    </div>
  </div>
</div>
<!-- Slider Section End -->
