<div id="callToMagazine">
    <a class="open" routerLink="/magazine/last">
        <span class="btn btn-primary" translate>See our Magazine</span>
    </a>
</div>
<div id="callToYoutube">
    <i class="fa fa-close"></i>
    <a class="open" href="https://www.youtube.com/watch?v=aUd1RZBOsOQ&list=UU-stYrp6PUBiHjMRS3nJj7g" target="_blank">
        <span class="btn btn-danger" translate>See our Youtube channel</span>
    </a>
</div>
<div id="popups" *ngIf="$popup | async">
    <i class="fa fa-close"></i>
    <a class="open" [href]="popup.link">
        <span class="header">{{ popup.header }}</span>
        <img [src]="'https://api.cornelder.co.mz/popups_photos/' + popup.filename">
        <span class="footer">{{ popup.footer }}</span>
    </a>
</div>


<app-slider [imgs]="images"></app-slider>
<app-home-slide-news></app-home-slide-news>
<app-services></app-services>
<app-testimonials *ngIf="false"></app-testimonials>
<app-home-map></app-home-map>
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
