import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-list-template',
  templateUrl: './news-list-template.component.html',
  styleUrls: ['./news-list-template.component.scss']
})
export class NewsListTemplateComponent implements OnInit {
  @Input() item: any;
  @Input() link = false;
  @Input() HideContent = false;
  host = '';
  lang = 'en';

  constructor(private api: ApiService, private router: Router) {
    this.host = this.api.getHost();
    this.lang = this.api.loadPrefLang();
  }

  ngOnInit() {
  }

  goToNewsDetails() {
    this.router.navigate([`/news/${this.item.id}_${encodeURIComponent(this.item.title[this.lang].split(' ').join('-'))}`]).then(() => {
      console.log('going to news details');
    });
  }
}
