<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Contact us</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a href="" translate>Contact us</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!-- Contact Page Section Start -->
<div class="contact-page-sec pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="contact-page-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13645.245982816712!2d34.835463!3d-19.82217!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x42394b3b08eeef20!2sCornelder+de+Mo%C3%A7ambique+S.A.R.L.!5e1!3m2!1spt-PT!2smz!4v1564248200398!5m2!1spt-PT!2smz" width="100%" height="250" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
        <div class="contact-info">
          <div class="row">
            <div class="col-md-4">
              <div class="contact-info-text">
                <div class="address-info-icon">
                  <img src="assets/img/icon/map-marker.png" alt=""/>
                </div>
                <span>PO. BOX 236 Porto Da Beira,</span>
                <span>Largo dos CFM. Beira</span>
                <span translate>SOFALA - MOZAMBIQUE</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="contact-info-text">
                <div class="address-info-icon">
                  <img src="assets/img/icon/envelop.png" alt=""/>
                </div>
                <span><a href="mailto:cornelder@cornelder.co.mz">cornelder@cornelder.co.mz</a></span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="contact-info-text">
                <div class="address-info-icon">
                  <img src="assets/img/icon/phone.png" alt=""/>
                </div>
                <span>TEL: (+258) 23 322 735 </span>
                <span>FAX: (+258) 23 322 736</span>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-field">
          <h2 translate>get touch with us</h2>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="single-input-field">
              <input type="text" name="name" [(ngModel)]="contact.name" placeholder="{{ 'Full Name' | translate }}"/>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="single-input-field">
              <input type="email" name="email" [(ngModel)]="contact.email" placeholder="{{ 'Your E-mail' | translate }}"/>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="single-input-field">
              <input type="text" name="phone" [(ngModel)]="contact.phone" placeholder="{{ 'Phone Number' | translate }}"/>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="single-input-field">
              <input type="text" name="address" [(ngModel)]="contact.address" placeholder="{{ 'Address' | translate }}"/>
            </div>
          </div>
          <div class="col-md-12 message-input">
            <div class="single-input-field">
              <textarea  name="comment" [(ngModel)]="contact.comment" placeholder="{{ 'Message' | translate }}"></textarea>
            </div>
          </div>
          <div class="single-input-fieldsbtn">
            <re-captcha *ngIf="!send_btn" (resolved)="resolved($event)" siteKey="6LcMjrEUAAAAAPFDU03KBmpDk9nqDWC3-8SXlOTU"></re-captcha>
            <input *ngIf="send_btn" type="submit" value="{{ 'send message' | translate }}" (click)="sendContact()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Contact Page Section End -->
<app-call-to-action></app-call-to-action>
