<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Our news</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a href="index.html" translate>Home</a></li>
            <li><a href="" translate>Our news</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!-- Service Section Start -->
<div class="service-sec pt-100 pb-100">
  <div class="container">
    <div class="row">
      <!-- Single Service Inner Start -->
      <div class="col-md-4 col-sm-6 col-xs-12 inner" *ngFor="let item of news">
        <app-news-list-template [item]="item" [link]="have_link"></app-news-list-template>
      </div>
      <!-- Single Service Inner End -->
      <div class="col-md-12 col-xs-12" *ngIf="pages > 0">
        <div class="pagination custom-pagination text-center">
          <ul class="pagination custom-pagination">
            <li *ngIf="page > 1"><a (click)="loadPage(page - 1)"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
            <li [ngClass]="{'active': page - 1 === p}" *ngFor="let p of getArrayFrom(pages)"><a (click)="loadPage(p + 1)">{{ p + 1 }}</a></li>
            <li *ngIf="page < pages"><a (click)="loadPage(page + 1)"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Service Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
