<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Berth plan</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a href="" translate>Berth plan</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Details Page Start -->
<!-- <div class="project-details-page pt-100 pb-100">
  <div class="container">
    <div class="row" *ngIf="document$ | async">
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="project-details-desc" *ngIf="document">
            <h2><a>{{ document.name[lang] }}</a></h2>
              <a [href]="host + '/documents/' + document.filename" [title]="document.name[lang]" target="_blank"><h3 translate>Open in new window</h3></a>
          </div>
          <div class="project-details-img" *ngIf="document">
            <iframe [src]="url"
                    style="height:100vh;width:70vw;"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="document2$ | async">
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="project-details-desc" *ngIf="document2">
            <h2><a>{{ document2.name[lang] }}</a></h2>
          </div>
          <div class="project-details-img" *ngIf="document2">
            <iframe [src]="url2"
                    style="height:100vh;width:70vw;"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

  </div>
</div> -->

<div class="project-details-page pt-100 pb-100" >
  <div class="container">
    <div class="row">
      <div class="col-md-12" *ngIf="document2$ | async">
        <div class="terminal-card">
          <h2>Container Terminal</h2>
          <div class="berth-plan-box">
            <div class="project-details-desc" *ngIf="document2">
              <h2><a>{{ document2.name[lang] }}</a></h2>
            </div>
            <div class="project-details-img" *ngIf="document2">
              <iframe [src]="url2"
                      style="height:100vh;width:100%;"
              ></iframe>
            </div>
          </div>
          <div class="button-group">
            <!-- <button class="btn btn-primary">View Detailed Plan</button> -->
            <a [href]="host + '/storage/documents/' + document2.filename" [title]="document2.name[lang]" target="_blank" class="btn btn-primary">Download Plan</a>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="document$ | async">
        <div class="terminal-card">
          <h2>General Cargo Terminal</h2>
          <div class="berth-plan-box">
            <div class="project-details-desc" *ngIf="document">
              <h2><a>{{ document.name[lang] }}</a></h2>
            </div>
            <div class="project-details-img" *ngIf="document">
              <iframe [src]="url"
                      style="height:100vh;width:100%;"
              ></iframe>
            </div>
          </div>
          <div class="button-group">
            <!-- <button class="btn btn-primary">View Detailed Plan</button> -->
            <a [href]="host + '/storage/documents/' + document.filename" [title]="document.name[lang]" target="_blank" class="btn btn-primary">Download Plan</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
