import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ApiService} from '../../services/api.service';
import {BehaviorSubject} from 'rxjs';
import {Router} from "@angular/router";

declare var Splide: any;
declare var $: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    moment = moment;
    $magazines = new BehaviorSubject([]);
    host = '';

    constructor(
        private service: ApiService,
        private router: Router,
    ) {
        this.host = this.service.getHost();
    }

    ngOnInit() {
        this.service.loadMagazines().subscribe((r: any[]) => {
            this.$magazines.next(r);
            if (r.length > 0) {
                let width = 130 + (100 * r.length);
                if (width > window.innerWidth) {
                    width = window.innerWidth;
                }
                $('.slider-container').width(width);
              const splide = new Splide('.splide', {
                type: 'loop',
                perPage: 3,
                perMove: 1,
              });
              splide.mount();
            }
        });
    }

  openMagazine(mag: any) {
      this.router.navigate(['/magazine/' + mag.id]);
  }
}
