import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {DinamicContentComponent} from './pages/dinamic-content/dinamic-content.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {MutaryComponent} from './pages/mutary/mutary.component';
import {NewsComponent} from './pages/news/news.component';
import {NewsDetailsComponent} from './pages/news-details/news-details.component';
import {ProjectsComponent} from './pages/projects/projects.component';
import {SocialResponsibilitiesComponent} from './pages/social-responsibilities/social-responsibilities.component';
import {PortPositionComponent} from './pages/port-position/port-position.component';
import {ContactsComponent} from './pages/contacts/contacts.component';
import {ShippingLinesComponent} from './pages/shipping-lines/shipping-lines.component';
import {TariffsComponent} from './pages/tariffs/tariffs.component';
import {PublicationsComponent} from './pages/publications/publications.component';
import {GalleryComponent} from './pages/gallery/gallery.component';
import {CorridorInitiativeComponent} from './pages/corridor-initiative/corridor-initiative.component';
import {CorridorInitiativeDetailsComponent} from './pages/corridor-initiative-details/corridor-initiative-details.component';
import {ProjectsDetailsComponent} from './pages/projects-details/projects-details.component';
import {MagazineComponent} from './pages/magazine/magazine.component';
import {BerthPlanComponent} from './pages/berth-plan/berth-plan.component';
import { ComplaintFormComponent } from './pages/complaint-form/complaint-form.component';
import { ComplaintPDFComponent } from './pages/complaint-pdf/complaint-pdf.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'about-us',
        component: AboutUsComponent,
    },
    {
        path: 'port/mutare',
        component: MutaryComponent,
    },
    {
        path: 'port/:area',
        component: DinamicContentComponent,
    },
    {
        path: 'safety',
        component: DinamicContentComponent,
    },
    {
        path: 'news',
        component: NewsComponent,
    },
    {
        path: 'news/:news_info',
        component: NewsDetailsComponent,
    },
    {
        path: 'projects',
        component: ProjectsComponent,
    },
    {
        path: 'socials/:project_info',
        component: ProjectsDetailsComponent,
    },
    {
        path: 'social-responsibilities',
        component: SocialResponsibilitiesComponent,
    },
    {
        path: 'port-position',
        component: PortPositionComponent,
    },
    {
        path: 'berth-plan',
        component: BerthPlanComponent,
    },
    {
        path: 'shipping-lines',
        component: ShippingLinesComponent,
    },
    {
        path: 'tariffs',
        component: TariffsComponent,
    },
    {
        path: 'gallery',
        component: GalleryComponent,
    },
    {
        path: 'publications',
        component: PublicationsComponent,
    },
    {
        path: 'corridor-initiative',
        component: CorridorInitiativeComponent,
    },
    {
        path: 'corridor-initiative/:corridor_info',
        component: CorridorInitiativeDetailsComponent,
    },
    {
        path: 'contacts',
        component: ContactsComponent,
    },
    {
        path: 'magazine/:id',
        component: MagazineComponent,
    },
    {
        path: 'complaint/form',
        component: ComplaintFormComponent,
    },
    {
        path: 'complaint/pdf',
        component: ComplaintPDFComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
