import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';

declare const $: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  send_btn = false
  contact = {
    name: '',
    email: '',
    phone: '',
    address: '',
    comment: '',
    token: null
  }

  constructor(private api: ApiService, private toastr: ToastrService) { }

  ngOnInit() {
    // setTimeout(() => {
    //   $('#main-menu > ul > li.active').removeClass('active');
    //   $($('#main-menu > ul > li')[13]).addClass('active');
    // }, 500);
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.contact.token = captchaResponse
      this.send_btn = true
    }
  }

  sendContact() {
    if (this.send_btn && this.contact.token != null) {
      this.api.sendContact(this.contact).subscribe(() => {
        this.toastr.success('Contact successfully sent.');
        this.contact = {
          name: '',
          email: '',
          phone: '',
          address: '',
          comment: '',
          token: null
        }
      })
    }
  }

}
