import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {BehaviorSubject} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
declare const $: any;

@Component({
  selector: 'app-berth-plan',
  templateUrl: './berth-plan.component.html',
  styleUrls: ['./berth-plan.component.scss']
})
export class BerthPlanComponent implements OnInit {
  host = '';
  lang = 'en';
  document = null;
  document2 = null;
  photo = '';
  document$ = new BehaviorSubject(false);
  document2$ = new BehaviorSubject(false);
  url: SafeUrl = '';
  url2: SafeUrl = '';

  constructor(
      private api: ApiService,
      private sanitizer: DomSanitizer
  ) {
    this.lang = this.api.loadPrefLang();
    this.host = this.api.getHost();
    this.loadFile();
    this.loadFile2();
  }

  ngOnInit() {
  }

  loadFile() {
    this.api.loadDocuments('berth', 'general', this.lang).subscribe((data: any) => {
      const firstKey = Object.keys(data)[0];
      const firstObject = data[firstKey];
      this.document = firstObject.docs[0];
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.host + '/documents/' + this.document.filename);
      this.document$.next(true);
    });
  }

  loadFile2() {
    this.api.loadDocuments('berth', 'containers', this.lang).subscribe((data: any) => {
      const firstKey = Object.keys(data)[0];
      const firstObject = data[firstKey];
      this.document2 = firstObject.docs[0];
      this.url2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.host + '/documents/' + this.document2.filename);
      this.document2$.next(true);
    });
  }
}
