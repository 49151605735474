<!-- Header Start -->
<header>
  <!-- Header Topbar Start -->
  <div class="header-top">
    <div class="container">
      <div class="row">
        <!-- Logo Start -->
        <div class="col-md-4 col-sm-4 col-xs-8 logo-area">
          <div class="logo">
            <a [routerLink]="['/']"><img src="assets/img/logo.jpg" alt=""/></a>
          </div>
          <div class="lang" translate>Language</div>
          <img [ngClass]="{'flag': true, 'active': lang === 'en'}" (click)="setLang('en')" src="assets/img/icon/uk_flag.png" />
          <img [ngClass]="{'flag': true, 'active': lang === 'pt'}" (click)="setLang('pt')" src="assets/img/icon/pt_flag.png" />
        </div>
        <!-- Logo End -->
        <div class="col-md-8 col-sm-8 mt-24">
          <div class="contact-intro">
            <div class="info-intro">
              <div class="info-text">
                <a [routerLink]="['/']"><h5 translate>Home</h5></a>
              </div>
            </div>
            <div class="info-intro">
<!--              <div class="info-icon">-->
<!--                <img src="assets/img/icon/world.png" alt=""/>-->
<!--              </div>-->
              <div class="info-text">
                <a [routerLink]="['/port-position']"><h5 translate>Port Position</h5></a>
<!--                <span>P.O. BOX 236. Porto da Beira</span>-->
              </div>
            </div>
            <div class="info-intro">
<!--              <div class="info-icon">-->
<!--                <img src="assets/img/icon/world.png" alt=""/>-->
<!--              </div>-->
              <div class="info-text">
                <a [routerLink]="['/berth-plan']"><h5 translate>Berth Plan</h5></a>
<!--                <span>P.O. BOX 236. Porto da Beira</span>-->
              </div>
            </div>
            <div class="info-intro">
<!--              <div class="info-icon">-->
<!--                <img src="assets/img/icon/clock.png" alt=""/>-->
<!--              </div>-->
              <div class="info-text">
                <a [routerLink]="['/gallery']"><h5 translate>Gallery</h5></a>
<!--                <span class="bold">24h/24h</span>-->
              </div>
            </div>
            <div class="info-intro">
<!--              <div class="info-icon">-->
<!--                <img src="assets/img/icon/call_center.png" alt=""/>-->
<!--              </div>-->
              <div class="info-text">
                <a [routerLink]="['/contacts']"><h5 translate>Contact us</h5></a>
<!--                <h5 translate>Call center </h5>-->
<!--                <span class="lines">TEL: (+258) 23 322 735<br>FAX: (+258) 23 322 736</span>-->
              </div>
            </div>

            <img [ngClass]="{'flag mobile': true, 'active': lang === 'en'}" (click)="setLang('en')" src="assets/img/icon/uk_flag.png" />
            <img [ngClass]="{'flag mobile': true, 'active': lang === 'pt'}" (click)="setLang('pt')" src="assets/img/icon/pt_flag.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header Topbar End -->
  <!-- Main Bar Start -->
  <div class="hd-sec">
    <div class="container">
      <div class="row">
        <div class="col-xs-8 phone-layout-logo">
          <a [routerLink]="['/']"><img src="assets/img/footer-logo.png" alt=""/></a>
        </div>
        <!-- Main Menu Start -->
        <div class="mobile-nav-menu"></div>
        <div class="col-md-12 col-sm-12 menu-left" style="line-height:11px;padding:0;">
          <div class="menu">
            <nav id="main-menu" class="main-menu">
              <ul>
                <li [ngClass]="{'active': isHomeActive}"><a [routerLink]="['/']" translate>Home</a></li>
                <li [ngClass]="{'active': isAboutActive}"><a translate>About Us </a>
                  <ul>
                    <li><a [routerLink]="['/about-us']" translate>Who we are, Mission, Vision and Values</a></li>
                    <li><a [routerLink]="['/safety']" translate>Safety</a></li>
                    <li><a [routerLink]="['/projects']" translate>Projects</a></li>
                    <li><a class="double-line" [routerLink]="['/social-responsibilities']"><span [innerHTML]="'Social Responsibilities' | translate"></span></a></li>
                    <li><a class="double-line" [routerLink]="['/shipping-lines']"><span [innerHTML]="'Shipping Lines' | translate"></span></a></li>
                  </ul>
                </li>
                <li [ngClass]="{'active': isFacilitiesActive}"><a><span [innerHTML]="'Port Facilities' | translate"></span></a>
                  <ul>
                    <li><a [routerLink]="['/port/container-terminal']" translate>Container Terminal</a></li>
                    <li><a [routerLink]="['/port/general-cargo']" translate>General Cargo Terminal</a></li>
                    <li><a [routerLink]="['/port/grain-terminal']" translate>Beira Grain Terminal</a></li>
<!--                    <li *ngIf="false"><a [routerLink]="['/port/coal-terminal']" translate>Coal terminal</a></li>-->
<!--                    <li *ngIf="false"><a [routerLink]="['/port/cargo-terminal']" translate>Cargo handling equipment</a></li>-->
<!--                    <li *ngIf="false"><a [routerLink]="['/port/mutare']" translate>Mutare dry port</a></li>-->
                  </ul>
                </li>
                <li [ngClass]="{'active': isTariffsActive}"><a class="w146" translate><span [innerHTML]="'Tariffs and Procedures' | translate"></span></a>
                  <ul>
                    <li><a [routerLink]="['/tariffs']" translate>Tarrifs book, Notices, ROC</a>
                  </ul>
                </li>
                <li [ngClass]="{'active': isNewsActive}"><a [routerLink]="['/news']" translate>News</a></li>

                <li [ngClass]="{'active': isPubsActive}"><a [routerLink]="['/publications']" translate>Publications</a></li>
                <li [ngClass]="{'active': isMagsActive}"><a [routerLink]="['/magazine/last']" translate>Magazine</a></li>
                <li [ngClass]="{'active': isComplaint}"><a><span [innerHTML]="'Whistleblowing' | translate"></span></a>
                  <ul>
                    <li *ngIf="complaintPDF$ | async as doc"><a [routerLink]="['/complaint/pdf']">{{ doc.name[this.lang] }}</a></li>
                    <li><a [routerLink]="['/complaint/form']" translate>Whistleblowing form</a></li>
                  </ul>
                </li>
<!--                <li><a class="double-line" [routerLink]="['/corridor-initiative']" >Beira Corridor Iniciative</a></li>-->
<!--                <li><a [routerLink]="['/contacts']" translate>Contact us</a></li>-->
              </ul>
            </nav>
            <!-- Menu Search Bar Start -->
            <div class="search-bar-icon" *ngIf="false">
              <div class="site-search">
                <span data-toggle="dropdown"><i class="fa fa-search"></i></span>
                <div class="search-forum dropdown-menu animation slideUpIn">
                  <form action="#">
                    <input placeholder="Search into site" type="text">
                    <input type="submit" value="Go"/>
                  </form>
                </div>
              </div>
            </div>
            <!-- Menu Search Bar End
            <div class="cart-button">
              <a href="#">
                <span class="cart-product">02</span>
              </a>
            </div>
            -->
          </div>
        </div>
        <!-- Main Menu End -->
      </div>
    </div>
  </div>
  <!-- Main Bar End -->
</header>
<!-- Header End -->
