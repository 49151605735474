<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Gallery</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a translate>Gallery</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Section Start -->
<div class="project-page-sec pt-100 pb-100">
  <div class="container">
    <div class="row">
      <!-- Single project Inner Start -->
      <div class="col-md-3 col-sm-6 col-xs-6 inner" *ngFor="let item of getDummyArray(photos)">
      </div>
      <div class="col-md-3 col-sm-6 col-xs-6 inner" *ngFor="let item of photos">
        <div class="single-project-inner">
          <div class="project-thumb">
            <img [src]="host + '/galleries/' + item.photo" alt=""/>
            <div class="project-thumb-overlay">
              <div class="project-icon">
                <a [href]="host + '/galleries/' + item.photo" class="fancybox" rel="gallery" [title]="'gallery' | translate"><i class="fa fa-external-link" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Single project Inner End -->
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
