import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

declare const $: any;

@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss']
})
export class TariffsComponent implements OnInit {
  area_name = '';
  area_title = '';
  area_description = '';
  area_description2 = '';
  lang = 'en';
  host = '';
  cats = [];

  constructor(private api: ApiService) {
    this.lang = this.api.loadPrefLang();
    this.host = this.api.getHost();
    this.loadContent('tariffs', 'tariffs');
  }

  ngOnInit() {
  }

  loadContent(area: string, subarea: string) {
    this.api.loadContent(area, subarea).subscribe((data: any) => {
      this.area_name = data.name;
      this.area_title = data.title;
      this.area_description = data.description;
      this.area_description2 = data.description2;
      // $('#loader').fadeOut(500)
    });
    this.cats = [];
    this.api.loadDocuments('tariffs', 'tariffs', this.lang).subscribe((data: any) => {
      Object.keys(data).forEach((k) => {
        if (data.hasOwnProperty(k)) {
          this.cats.push(data[k]);
        }
      });
      console.log(this.cats);
    });
  }

  getDummyArray(photos) {
    const s = photos ? photos.length : 0;
    if (s === 0 || s > 2) {
      return [];
    } else if (s === 1 || s === 2) {
      return Array.from(Array(1).keys());
    }
  }

}
