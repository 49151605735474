import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

declare const $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  lang = 'en'
  content = {}
  photo = '';

  constructor(private api: ApiService) {
    this.lang = this.api.loadPrefLang()
    this.loadContent('about-us', 'who-we-are')
    this.loadContent('about-us', 'about-the-port')
    this.loadContent('about-us', 'our-vision')
    this.loadContent('about-us', 'our-mission')
    this.loadContent('about-us', 'our-values')
    this.loadContentPhotos();
  }

  ngOnInit() {
    // setTimeout(() => {
    //   $('#main-menu > ul > li.active').removeClass('active');
    //   $($('#main-menu > ul > li')[1]).addClass('active');
    // }, 500);
  }

  loadContent(area: string, subarea: string) {
    this.api.loadContent(area, subarea).subscribe((data: any) => {
      this.content[subarea] = {
        title: data.title,
        description: data.description,
        description2: data.description2
      }
    })
  }

  loadContentPhotos() {
    this.api.loadContentPhotos('about-us', 'family').subscribe((data: any) => {
      this.photo = this.api.getHost() + '/storage/dinamic_photos/' + data.filename
    })
  }

  splitContent(text) {
    if (text.length > 0) {
      return text.replace(/<[^>]*>?/gm, '').split(';')
    }
    return [];
  }
}
