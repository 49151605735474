import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  lang = 'en';
  content = {};
  photos = [];
  host = '';

  constructor(private api: ApiService) {
    this.lang = this.api.loadPrefLang();
    this.host = this.api.getHost() + '/storage/dinamic_photos/';
    this.loadContentPhotos('home', null);
    this.loadContent('home', 'content_1');
    this.loadContent('home', 'content_2');
    this.loadContent('home', 'content_3');
    this.loadContent('home', 'content_4');
    this.loadContent('home', 'table');
  }

  ngOnInit() {
  }

  loadContent(area: string, subarea: string) {
    this.api.loadContent(area, subarea).subscribe((data: any) => {
      this.content[subarea] = {
        name: data.name,
        title: data.title,
        description: data.description,
        description2: data.description2
      };
    });
  }

  splitContent(text) {
    if (text.length > 0) {
      return text.replace(/<[^>]*>?/gm, '').split(';');
    }
    return [];
  }

  loadContentPhotos(area: string, subarea: any) {
    this.api.loadContentPhotos(area, subarea).subscribe((data: any) => {
      this.photos = data;
    });
  }

  getPhoto = (subarea: string) => {
    const photos = this.photos.filter(r => r.subarea === subarea);
    if (photos[0]) {
      return photos[0].filename;
    }
  }

}
