import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

declare const $: any;

@Component({
  selector: 'app-complaint-form',
  templateUrl: './complaint-form.component.html',
  styleUrls: ['./complaint-form.component.scss']
})
export class ComplaintFormComponent implements OnInit {
  id = '';
  $form = new BehaviorSubject(false);
  form = null;
  lang = 'en'

  constructor(
      private service: ApiService,
      private sanitizer: DomSanitizer,
  ) {
    this.loadContent();
  }

  ngOnInit() {
    this.lang = this.service.loadPrefLang();
  }

  loadContent() {
    this.service.loadContent('menu', 'complaint').subscribe((data: any) => {
      console.log(data);
      this.$form.next(true);
      this.form = this.sanitizer.bypassSecurityTrustResourceUrl(data.description[this.lang]);
    });
  }

  resizeIframe(iframe) {
    iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
  }
}
