import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../../services/api.service';

declare const $: any

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  lang = 'en'
  host = ''
  photos = []

  constructor(private api: ApiService, private route: ActivatedRoute) {
    this.lang = this.api.loadPrefLang()
    this.host = this.api.getHost()
    this.loadContent('gallery', 'gallery')
  }

  ngOnInit() {
    setTimeout(() => {
      $('#main-menu > ul > li.active').removeClass('active');
      $($('#main-menu > ul > li')[10]).addClass('active');
    }, 500);
  }

  loadContent(area: string, subarea: string) {
    this.api.loadGallery(area, subarea).subscribe((data: any) => {
      this.photos = data
      setTimeout(() => {
        $(document).ready(function() {
            $('.fancybox').fancybox();
        });
      }, 1000)
    })
  }

  getDummyArray(photos) {
    const s = photos ? photos.length : 0
    if (s === 0 || s > 2) {
      return [];
    } else if (s === 1 || s === 2) {
      return Array.from(Array(1).keys())
    }
  }
}
