<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Magazine</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a *ngIf="$magazine | async as magazine">{{ magazine.name }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Section Start -->
<div class="project-page-sec pt-10 pb-100">
  <div class="container">
    <!-- Section Title End -->
    <div class="row" *ngIf="$magazine | async">
      <div class="col-md-12">
        <iframe [src]="magazine" width="100%" style="min-height:800px;"></iframe>
      </div>
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
