import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

declare const $: any

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  lang = 'en'
  host = ''
  projects = []
  page = 1
  pages = 0

  constructor(private api: ApiService) {
    this.lang = this.api.loadPrefLang()
    this.host = this.api.getHost()
    this.loadProjects(this.page)
  }

  ngOnInit() {
    setTimeout(() => {
      $('#main-menu > ul > li.active').removeClass('active');
      $($('#main-menu > ul > li')[5]).addClass('active');
    }, 500);
  }

  getArrayFrom(n: number) {
    return Array.from(Array(n).keys())
  }

  loadProjects(page: number) {
    this.api.getProjects(page).subscribe((result: any) => {
      this.projects = result.data
      $('#loader').fadeOut(500)
      this.pages = result.last_page
      this.page = page
      setTimeout(() => {
        $(document).ready(function() {
            $('.fancybox').fancybox();
        });
      }, 500)
    })
  }
}
