import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private host = environment.host;

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getHost() {
    return this.host
  }

  loadPrefLang() {
    const l = window.localStorage.getItem(`${environment.project}_pref_lang`)
    if (!l) {
      return environment.prefered_lang
    }
    return l
  }

  setPrefLang(lang: string) {
    window.localStorage.setItem(`${environment.project}_pref_lang`, lang)
  }

  getAvailableLangs() {
    return environment.languages
  }

  loadContent(area: string, subarea: string) {
    return this.http.get(this.host + `/content/${area}/${subarea}`, this.httpOptions);
  }

  loadContentPhotos(area: string, subarea: any) {
    return this.http.get(this.host + `/content-photos/${area}${subarea ? '/' + subarea : ''}`, this.httpOptions);
  }

  loadGallery(area: string, subarea: string) {
    return this.http.get(this.host + `/gallery/${area}/${subarea}`, this.httpOptions);
  }

  loadDocuments(area: string, subarea: string, lang: string) {
    return this.http.get(this.host + `/docs/${area}/${subarea}?lang=${lang}`, this.httpOptions);
  }

  loadPublications(lang: string) {
    return this.http.get(this.host + `/pubs/list?lang=${lang}`, this.httpOptions);
  }

  latestNews() {
    return this.http.get(this.host + `/news/latest`, this.httpOptions);
  }

  highlightNews() {
    return this.http.get(this.host + `/news/highlight`, this.httpOptions);
  }

  getNews(page: number) {
    return this.http.get(this.host + `/news/list?page=${page}`, this.httpOptions);
  }

  getCorridor(page: number) {
    return this.http.get(this.host + `/corridor/list?page=${page}`, this.httpOptions);
  }

  getNewsById(id: any) {
    return this.http.get(this.host + `/news/open/${id}`, this.httpOptions);
  }

  getCorridorById(id: any) {
    return this.http.get(this.host + `/corridor/open/${id}`, this.httpOptions);
  }

  getProjects(page: number) {
    return this.http.get(this.host + `/projects/list?page=${page}`, this.httpOptions);
  }

  getSocialProjects(page: number) {
    return this.http.get(this.host + `/social/list?page=${page}`, this.httpOptions);
  }

  getSocialsById(id: any) {
    return this.http.get(this.host + `/social/open/${id}`, this.httpOptions);
  }

  sendContact(contact: any) {
    return this.http.post(this.host + `/contacts/send`, contact, this.httpOptions);
  }

  loadMagazines() {
    return this.http.get(this.host + `/magazines/list`, this.httpOptions);
  }

  loadMagazine(id: any) {
    return this.http.get(this.host + `/magazines/show/${id}`, this.httpOptions);
  }

  loadPopups(lang: string) {
    return this.http.get(this.host + `/popups/latest?lang=${lang}`, this.httpOptions);
  }
}
