<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1>{{ area_name ? area_name[lang] : '' }}</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a>{{ area_name ? area_name[lang] : '' }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Section Start -->
<div class="project-page-sec pt-100 pb-100">
  <div class="container">
    <!-- Section Title Start -->
    <div class="row">
      <br>
      <div class="col-md-10">
        <div class="sec-title">
          <h1>{{ area_title ? area_title[lang] : '' }}</h1>
          <p class="text-justify" *ngIf="area_description" [innerHtml]="area_description[lang]"></p>
          <div class="mt-20" *ngIf="area_description2" [innerHtml]="area_description2[lang]"></div>
        </div>
      </div>
      <div class="col-md-2 xs-hidden">
        <div class="sidebar">
          <div class="widget-two">
            <h1 translate>Other areas</h1>
            <div class="all_r_pst">
              <div class="media" *ngFor="let ln of areas">
                <div class="relative-post" >
                  <div class="media-body">
                    <div class="single_r_dec">
                      <h3><a [routerLink]="ln.link" translate>{{ ln.title }}</a></h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section Title End -->
    <div class="row">
      <!-- Single project Inner Start -->
      <div class="col-md-3 col-sm-6 col-xs-6 inner" *ngFor="let item of getDummyArray(photos)">
      </div>
      <div [ngClass]="{'col-md-3 col-sm-6 col-xs-6': photos.length > 1, 'inner': true, 'col-md-6 col-sm-12 col-xs-12': photos.length == 1}" *ngFor="let item of photos">
        <div class="single-project-inner">
          <div class="project-thumb">
            <img [src]="host + '/galleries/' + item.photo" alt=""/>
            <div class="project-thumb-overlay">
              <div class="project-icon">
                <a [href]="host + '/galleries/' + item.photo" class="fancybox" rel="gallery" [title]="area_name ? area_name[lang] : ''"><i class="fa fa-external-link" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Single project Inner End -->
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
