import { Component, OnInit, Input } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';

declare const $: any;

@Component({
  selector: 'app-home-slide-news',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderNewsComponent implements OnInit {
  lang = 'en';
  news = null;
  host = '';

  constructor(private translate: TranslateService, private api: ApiService, private router: Router) {
    this.host = this.api.getHost();
    this.lang = this.api.loadPrefLang();
    this.api.highlightNews().subscribe((data: any) => {
      if (data.length > 0) {
        this.news = data;
        setTimeout(() => {
          $('.all-slide2').owlCarousel({
              items: 1,
              nav: true,
              dots: true,
              autoplay: true,
              loop: true,
              navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
              mouseDrag: false,
              touchDrag: false,
              autoplayHoverPause: true,
              smartSpeed: 2000
          });

          $('.all-slide2').on('translate.owl.carousel', function(){
              $('.all-slide2 .slider-text2 h1').removeClass('animated fadeInUp').css('opacity', '0');
              $('.all-slide2 .slider-text2 p').removeClass('animated fadeInDown').css('opacity', '0');
              $('.all-slide2 .slider-text2 ul').removeClass('animated fadeInDown').css('opacity', '0');
          });

          $('.all-slide2').on('translated.owl.carousel', function(){
              $('.all-slide2 .slider-text2 h1').addClass('animated fadeInUp').css('opacity', '1');
              $('.all-slide2 .slider-text2 p').addClass('animated fadeInDown').css('opacity', '1');
              $('.all-slide2 .slider-text2 ul').addClass('animated fadeInDown').css('opacity', '1');
          });
        }, 1000);
      }
    });
  }

  ngOnInit() {
  }

  goToNews(n) {
    const link = encodeURIComponent(n.title[this.lang].split(' ').join('-'));
    this.router.navigate([`/news/${n.id}_${link}`]);
  }

}
