<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Port position</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a href="" translate>Port position</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Details Page Start -->
<div class="project-details-page pt-100 pb-100">
  <div class="container">
    <div class="row" *ngIf="document$ | async">
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="project-details-desc" *ngIf="document">
            <h2><a translate>Port position</a></h2>
            <a [href]="host + '/documents/' + document.filename" [title]="document.name[lang]" target="_blank"><h3 translate>UPDATED PORT POSITON</h3></a>
          </div>
          <div class="project-details-img" *ngIf="document">
<!--            <a [href]="host + '/documents/' + document.filename" [title]="document.name[lang]" target="_blank">-->
<!--              <img [src]="photo" alt=""/>-->
<!--            </a>-->
            <iframe [src]="url"
                    style="height:100vh;width:70vw;"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="row" *ngIf="document2$ | async">-->
<!--      <div class="col-md-12">-->
<!--        <div class="col-md-12">-->
<!--          <div class="project-details-desc" *ngIf="document2">-->
<!--            <h2><a translate>Berth plan</a></h2>-->
<!--&lt;!&ndash;            <a [href]="host + '/documents/' + document2.filename" [title]="document2.name[lang]" target="_blank"><h3 translate>BERTH PLAN</h3></a>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="project-details-img" *ngIf="document2">-->
<!--&lt;!&ndash;            <a [href]="host + '/documents/' + document.filename" [title]="document.name[lang]" target="_blank">&ndash;&gt;-->
<!--&lt;!&ndash;              <img [src]="photo" alt=""/>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--            <iframe [src]="url2"-->
<!--                    style="height:100vh;width:70vw;"-->
<!--            ></iframe>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
