import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {BehaviorSubject} from "rxjs";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
declare const $: any;

@Component({
  selector: 'app-port-position',
  templateUrl: './port-position.component.html',
  styleUrls: ['./port-position.component.scss']
})
export class PortPositionComponent implements OnInit {
  host = '';
  lang = 'en';
  document = null;
  // document2 = null;
  photo = '';
  document$ = new BehaviorSubject(false);
  // document2$ = new BehaviorSubject(false);
  url: SafeUrl = '';
  // url2: SafeUrl = '';

  constructor(
      private api: ApiService,
      private sanitizer: DomSanitizer
  ) {
    this.lang = this.api.loadPrefLang();
    this.host = this.api.getHost();
    this.loadFile();
    // this.loadFile2();
    this.loadContentPhotos();
  }

  ngOnInit() {
  }

  loadFile() {
    this.api.loadDocuments('port', 'position', this.lang).subscribe((data: any) => {
      this.document = data.general.docs[0];
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.host + '/documents/' + this.document.filename);
      this.document$.next(true);
    });
  }

  // loadFile2() {
  //   this.api.loadDocuments('port', 'berth', this.lang).subscribe((data: any) => {
  //     this.document2 = data.general.docs[0];
  //     this.url2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.host + '/documents/' + this.document2.filename);
  //     this.document2$.next(true);
  //   });
  // }

  loadContentPhotos() {
    this.api.loadContentPhotos('port-position', 'cover').subscribe((data: any) => {
      this.photo = this.host + '/content_photos/' + data.filename;
    });
  }
}
