<!-- Blog Section Start -->
<div class="highlight">
  <div class="high_body">
    <div class="close_high">
      <i class="fa fa-times"></i>
    </div>
    <div class="col-md-12 col-sm-12">
      <app-news-list-template *ngIf="highlight" [item]="highlight"></app-news-list-template>
    </div>
  </div>
</div>
<div id="news" class="blog-sec pt-100">
  <div class="container">
    <!-- Section Title Start -->
    <div class="row">
      <div class="col-md-12">
        <div class="sec-title">
          <h1 translate>our latest news</h1>
          <div class="border-bottom"></div>
        </div>
      </div>
    </div>
    <!-- Section Title End -->
    <div class="row">
      <!-- Single Blog Item Start -->
      <div class="col-md-4 col-sm-6" *ngFor="let item of news">
        <app-news-list-template [item]="item" [HideContent]="HideContent"></app-news-list-template>
      </div>
      <!-- Single Blog Item End -->
    </div>
  </div>
</div>
<!-- Blog Section End -->
