<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Social_Responsibilities</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a translate>Social_Responsibilities</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Section Start -->
<div class="project-page-sec pt-100 pb-100">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-6 col-xs-12 inner" *ngFor="let item of projects">
        <app-social-list-template [item]="item"></app-social-list-template>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12" *ngIf="pages > 0">
        <div class="pagination custom-pagination text-center">
          <ul class="pagination custom-pagination">
            <li><a (click)="loadPage(page - 1)"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
            <li [ngClass]="{'active': page === (p+1)}" *ngFor="let p of getArrayFrom(pages)"><a (click)="loadPage(p + 1)">{{ p + 1 }}</a></li>
            <li><a (click)="loadPage(page + 1)"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
