import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';

declare const $: any;

@Component({
    selector: 'app-social-responsibilities',
    templateUrl: './social-responsibilities.component.html',
    styleUrls: ['./social-responsibilities.component.scss']
})
export class SocialResponsibilitiesComponent implements OnInit {
    lang = 'en';
    host = '';
    projects = [];
    page = 1;
    pages = 0;

    constructor(private api: ApiService) {
        this.lang = this.api.loadPrefLang();
        this.host = this.api.getHost();
    }

    ngOnInit() {
        this.loadPage(this.page);
    }

    getArrayFrom(n: number) {
        return Array.from(Array(n).keys())
    }

    loadPage(p: number) {
        this.api.getSocialProjects(p).subscribe((result: any) => {
            this.projects = result.data
            this.pages = result.last_page
            this.page = p
            setTimeout(() => {
                $(document).ready(function () {
                    $('.fancybox').fancybox();
                });
            }, 500)
        })
    }
}
