import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-corridor-list-template',
  templateUrl: './corridor-list-template.component.html',
  styleUrls: ['./corridor-list-template.component.scss']
})
export class CorridorListTemplateComponent implements OnInit {
  @Input() item: any
  @Input() HideContent = false
  host = ''
  lang = 'en'

  constructor(private api: ApiService, private router: Router) {
    this.host = this.api.getHost()
    this.lang = this.api.loadPrefLang()
  }

  ngOnInit() {
  }

  goToCorridorDetails() {
    this.router.navigate([`/corridor-initiative/${this.item.id}_${encodeURIComponent(this.item.title[this.lang].split(' ').join('-'))}`]).then(() => {
      console.log('going to corridor item details');
    });
  }
}
