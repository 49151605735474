<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1>Beira corridor initiative</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a>Beira corridor initiative</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Section Start -->
<div class="project-page-sec pt-100 pb-100">
  <div class="container">
    <div class="row">
      <!-- Single Service Inner Start -->
      <div class="col-md-4 col-sm-6 col-xs-12 inner" *ngFor="let item of corridor_items">
        <app-corridor-list-template [item]="item"></app-corridor-list-template>
      </div>
      <!-- Single Service Inner End -->
      <div class="col-md-12 col-xs-12" *ngIf="pages > 0">
        <div class="pagination custom-pagination text-center">
          <ul class="pagination custom-pagination">
            <li *ngIf="page > 1"><a (click)="loadPage(page - 1)"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
            <li [ngClass]="{'active': page === 0}" *ngFor="let p of getArrayFrom(pages)"><a (click)="loadPage(p + 1)">{{ p + 1 }}</a></li>
            <li *ngIf="page < pages"><a (click)="loadPage(page + 1)"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Section Title Start -->
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="sec-title">
          <h1>{{ area_title[lang] }}</h1>
          <p class="text-justify" [innerHtml]="area_description[lang]"></p>
          <div class="mt-20" [innerHtml]="area_description2[lang]"></div>
        </div>
      </div>
    </div> -->
    <!-- Section Title End -->
    <!-- <div class="row"> -->
      <!-- Single project Inner Start -->
      <!-- <div class="col-md-3 col-sm-6 col-xs-6 inner" *ngFor="let item of getDummyArray(photos)">
      </div>
      <div ngClass="{'col-md-3 col-sm-6 col-xs-6': photos.length > 1, 'inner': true, 'col-md-6 col-sm-12 col-xs-12': photos.length == 1}" *ngFor="let item of photos">
        <div class="single-project-inner">
          <div class="project-thumb">
            <img [src]="host + '/galleries/' + item.photo" alt=""/>
            <div class="project-thumb-overlay">
              <div class="project-icon">
                <a [href]="host + '/galleries/' + item.photo" class="fancybox" rel="gallery" [title]="area_name[lang]"><i class="fa fa-external-link" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Single project Inner End -->
    <!-- </div> -->
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
