import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from "@angular/router";

declare const $: any;

@Component({
  selector: 'app-projects-details',
  templateUrl: './projects-details.component.html',
  styleUrls: ['./projects-details.component.scss']
})
export class ProjectsDetailsComponent implements OnInit {
  host = ''
  lang = 'en'
  id = ''
  project = {
    id: '',
    title: {
      en: '',
      pt: ''
    },
    description: {
      en: ''
    },
    photos: [],
    created_at: '',
    link: ''
  }

  constructor(private api: ApiService, private route: ActivatedRoute, private router: Router) {

    this.host = this.api.getHost()
    this.lang = this.api.loadPrefLang()

    this.route.paramMap.subscribe(params => {
      this.id = params.get("project_info").split('_')[0];
      if (this.id) {
        this.api.getSocialsById(this.id).subscribe((proj: any) => {
          this.project = proj;
          setTimeout(() => {
            $(document).ready(function() {
                $('.fancybox').fancybox();
            });
          }, 500)
        });
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      $('#main-menu > ul > li.active').removeClass('active');
      $($('#main-menu > ul > li')[9]).addClass('active');
    }, 500);
  }

  getShareName() {
    return encodeURIComponent(this.project.title[this.lang])
  }

  getShareURL() {
    return `https://cornelder.mz.co/socials/${this.project.id}_${encodeURIComponent(this.project.title[this.lang].split(' ').join('-'))}`
  }
}
