import { Component, OnInit } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      $(".all-testimonial").owlCarousel({
        loop: true,
  			autoplay: true,
  			pagination:false,
  			nav:false,
  			dots:true,
  			items :1,
  			responsive:{
  				0:{
  					items:1
  				},
  				600:{
  					items:1
  				},
  				768:{
  					items:1
  				},
  				1000:{
  					items:1
  				}
  			}
		  });
    }, 1000)
  }

}
