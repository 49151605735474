<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="images-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1>{{ project.title[lang] }}</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a [routerLink]="['/social-responsibilities']" translate>Social responsabilities</a></li>
            <li><a >{{ project.title[lang] }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!-- Blog Section Start -->
<div class="blog-details-page pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="media">
          <div class="single-post">
            <div class="single-post-thumb">
              <img *ngIf="project.photos.length > 0" [src]="host + '/storage/social_projects/' + project.photos[0].photo" alt=""/>
              <div class="single-post-thumb-overlay">
                <div class="post-meta">
                  <span class="post-date">{{ project.created_at | amLocale:lang | amDateFormat:'DD' }}</span>
                  <span class="post-month">{{ project.created_at | amLocale:lang | amDateFormat:'MMM' }}</span>
                </div>
              </div>
            </div>
            <div class="single-post-text">
              <div class="post-meta-info">
                <h2><a>{{ project.title[lang] }}</a></h2>
              </div>
              <p [innerHtml]="project.description[this.lang]" style="height:auto;">
              </p>
              <div class="share-button">
                <ul>
                  <li><a href="http://twitter.com/share?text={{getShareName()}}&url={{getShareURL()}}" target="_blank" class="twitter-share-button"><i class="fa fa-twitter"></i> twitter</a></li>
                  <li><a href="https://www.facebook.com/sharer/sharer.php?u={{getShareURL()}}" target="_blank" class="facebook-share-button"><i class="fa fa-facebook"></i> facebook</a></li>
                </ul>
              </div>
            </div>
            <div class="contact-field" *ngIf="false">
              <h3 class="comment-reply-title">live your comment</h3>
              <form action="#" method="post" id="commentform">
                 <div class="row form-fields">
                  <p class="comment-author col-md-6"><input placeholder="Full Name" id="author" name="author" value="" size="30" type="text"></p>
                  <p class="author-email col-md-6"><input placeholder="Your E-mail" id="email" name="email" value="" size="30" type="text"></p>
                 </div>
                 <p class="comment-form">
                <textarea placeholder="Write Your Comment" id="comment" name="comment" cols="36" rows="8" aria-required="true"></textarea>
                 </p>
                 <p class="form-submit"><input name="submit" id="submit" class="submit" value="Post Comment" type="submit">
                 </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="sidebar">
          <div class="widget-two">
            <h1 translate>other photos</h1>
            <div class="all_r_pst">
              <div class="media" *ngFor="let ph of project.photos; let i = index">
                <div class="relative-post" *ngIf="i > 0">
                  <div class="relative-post-thumb">
                    <a><img [src]="host + '/storage/social_projects/' + ph.photo" alt="" class="fancybox" style="cursor:pointer;"/></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Blog Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
