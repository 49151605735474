<!-- Page Heading Section Start -->
<div class="pagehding-sec">
    <div class="images-overlay"></div>
    <div class="container" *ngIf="news">
        <div class="row">
            <div class="col-md-12">
                <div class="page-heading">
                    <h1>{{ news.title[lang] }}</h1>
                </div>
                <div class="breadcrumb-list">
                    <ul>
                        <li><a [routerLink]="['/']">Home</a></li>
                        <li><a [routerLink]="['/news']">News</a></li>
                        <li><a>{{ news.title[lang] }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Page Heading Section End -->
<!-- Blog Section Start -->
<div class="blog-details-page pt-100 pb-70">
    <div class="container" *ngIf="news">
        <div class="row">
            <div class="col-md-8">
                <div class="media">
                    <div class="single-post">
                        <div class="single-post-thumb">
                            <!--              <img *ngIf="news.photos.length > 0" [src]="host + '/news_pictures/' + news.photo" alt=""/>-->
                            <gallery [items]="photos"></gallery>
                            <div class="single-post-thumb-overlay">
                                <div class="post-meta">
                                    <span class="post-date">{{ news.created_at | amLocale:lang | amDateFormat:'DD' }}</span>
                                    <span class="post-month">{{ news.created_at | amLocale:lang | amDateFormat:'MMM' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="single-post-text">
                            <div class="post-meta-info">
                                <h2><a>{{ news.title[lang] }}</a></h2>
                                <div class="post-meta-data">
                                    <ul>
                                        <li *ngIf="news.link"><a class="link" target="_blank" href="{{ news.link }}"><i
                                                class="fa fa-link"></i>{{ news.link }}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <p [innerHtml]="news.body[this.lang]" style="height:auto;">
                            </p>
                            <div class="share-button">
                                <ul *ngIf="news">
                                    <li><a href="http://twitter.com/share?text={{getShareName()}}&url={{getShareURL(news)}}"
                                           target="_blank" class="twitter-share-button"><i class="fa fa-twitter"></i>
                                        twitter</a></li>
                                    <li><a href="https://www.facebook.com/sharer/sharer.php?u={{getShareURL(news)}}"
                                           target="_blank" class="facebook-share-button"><i class="fa fa-facebook"></i>
                                        facebook</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="contact-field" *ngIf="false">
                            <h3 class="comment-reply-title">live your comment</h3>
                            <form action="#" method="post" id="commentform">
                                <div class="row form-fields">
                                    <p class="comment-author col-md-6"><input placeholder="Full Name" id="author"
                                                                              name="author" value="" size="30"
                                                                              type="text"></p>
                                    <p class="author-email col-md-6"><input placeholder="Your E-mail" id="email"
                                                                            name="email" value="" size="30" type="text">
                                    </p>
                                </div>
                                <p class="comment-form">
                                    <textarea placeholder="Write Your Comment" id="comment" name="comment" cols="36"
                                              rows="8" aria-required="true"></textarea>
                                </p>
                                <p class="form-submit"><input name="submit" id="submit" class="submit"
                                                              value="Post Comment" type="submit">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="sidebar">
                    <div class="widget-two">
                        <h1 translate>recent news</h1>
                        <div class="all_r_pst">
                            <div class="media" *ngFor="let ln of latest">
                                <div class="relative-post" (click)="goToNewsDetails(ln)">
                                    <div class="relative-post-thumb">
                                        <a><img [src]="host + '/news/' + ln.photo" alt=""/></a>
                                    </div>
                                    <div class="media-body">
                                        <div class="single_r_dec">
                                            <h3><a href="#">{{ ln.title[lang] }}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Blog Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
