import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-social-list-template',
  templateUrl: './social-list-template.component.html',
  styleUrls: ['./social-list-template.component.scss']
})
export class SocialListTemplateComponent implements OnInit {
  @Input() item: any
  @Input() HideContent = false
  host = ''
  lang = 'en'

  constructor(private api: ApiService, private router: Router) {
    this.host = this.api.getHost()
    this.lang = this.api.loadPrefLang()
  }

  ngOnInit() {
  }

  goToSocialDetails() {
    this.router.navigate([`/socials/${this.item.id}_${encodeURIComponent(this.item.title[this.lang].split(' ').join('-'))}`]).then(() => {
      console.log('going to social project details');
    });
  }
}
