import { Component, OnInit, Input } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

declare const $: any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() imgs = []

  slogan = ''

  constructor(private translate: TranslateService) {
    this.translate.get("The shortest, quickest & safest route to the sea for the southern Africa hinterland.").subscribe(trans =>{
      this.slogan = trans
    })
  }

  ngOnInit() {
    setTimeout(() => {
      $(".all-slide").owlCarousel({
          items: 1,
          nav: true,
          dots: true,
          autoplay: true,
          loop: true,
          navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
          mouseDrag: false,
          touchDrag: false,
      });

      $(".all-slide").on("translate.owl.carousel", function(){
          $(".all-slide .slider-text h1").removeClass("animated fadeInUp").css("opacity", "0");
          $(".all-slide .slider-text p").removeClass("animated fadeInDown").css("opacity", "0");
          $(".all-slide .slider-text ul").removeClass("animated fadeInDown").css("opacity", "0");
      });

      $(".all-slide").on("translated.owl.carousel", function(){
          $(".all-slide .slider-text h1").addClass("animated fadeInUp").css("opacity", "1");
          $(".all-slide .slider-text p").addClass("animated fadeInDown").css("opacity", "1");
          $(".all-slide .slider-text ul").addClass("animated fadeInDown").css("opacity", "1");
      });
    })
  }

}
