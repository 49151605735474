<!--project Details Page Start -->
<div class="project-details-page pt-20 pb-100">
  <div class="container">
    <div class="row" *ngIf="document$ | async">
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="project-details-desc" *ngIf="document">
            <h2><a>{{ document.name[this.lang] }}</a></h2>
          </div>
          <div class="project-details-img" *ngIf="document">
            <iframe [src]="url"
                    style="height:100vh;width:70vw;"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
