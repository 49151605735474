import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

declare const $: any

@Component({
  selector: 'app-corridor-initiative',
  templateUrl: './corridor-initiative.component.html',
  styleUrls: ['./corridor-initiative.component.scss']
})
export class CorridorInitiativeComponent implements OnInit {
  corridor_items = []
  pages = 0
  page = 1

  constructor(private api: ApiService) {}

  ngOnInit() {
    setTimeout(() => {
      $('#main-menu > ul > li.active').removeClass('active');
      $($('#main-menu > ul > li')[12]).addClass('active');
    }, 500);
    this.loadPage(this.page)
  }

  getArrayFrom(n: number) {
    return Array.from(Array(n).keys())
  }

  loadPage(p: number) {
    this.api.getCorridor(p).subscribe((result: any) => {
      this.corridor_items = result.data
      this.pages = result.last_page
      this.page = p
    })
  }
}
