import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from "@angular/router";

declare const $: any;

@Component({
  selector: 'app-corridor-initiative-details',
  templateUrl: './corridor-initiative-details.component.html',
  styleUrls: ['./corridor-initiative-details.component.scss']
})
export class CorridorInitiativeDetailsComponent implements OnInit {
  host = ''
  lang = 'en'
  id = ''
  item = {
    id: '',
    title: {
      en: '',
      pt: ''
    },
    body: {
      en: ''
    },
    photo: '',
    created_at: '',
    link: ''
  }
  latest = []

  constructor(private api: ApiService, private route: ActivatedRoute, private router: Router) {

    this.host = this.api.getHost()
    this.lang = this.api.loadPrefLang()

    this.route.paramMap.subscribe(params => {
      this.id = params.get("corridor_info").split('_')[0];
      if (this.id) {
        this.api.getCorridorById(this.id).subscribe((item: any) => {
          this.item = item;
        });
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      $('#main-menu > ul > li.active').removeClass('active');
      $($('#main-menu > ul > li')[2]).addClass('active');
    }, 500);

    this.api.latestNews().subscribe((data: any) => {
      this.latest = data
    })
  }

  goToNewsDetails(news: any) {
    this.router.navigate([`/news/${news.id}_${encodeURIComponent(news.title[this.lang].split(' ').join('-'))}`]).then(() => {
      console.log('going to news details');
    });
  }

  getShareName() {
    return encodeURIComponent(this.item.title[this.lang])
  }

  getShareURL() {
    return `https://cornelder.mz.co/corridor-initiative/${this.item.id}_${encodeURIComponent(this.item.title[this.lang].split(' ').join('-'))}`
  }
}
