import { Component, OnInit } from '@angular/core';
declare const $: any;

@Component({
  selector: 'app-shipping-lines',
  templateUrl: './shipping-lines.component.html',
  styleUrls: ['./shipping-lines.component.scss']
})
export class ShippingLinesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
