<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="images-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Mutare dry port</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a translate>Mutare dry port</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!-- Blog Section Start -->
<div class="blog-sec pt-100 pb-100">
  <div class="container">
    <div class="row">
      <!-- Single Blog Item Start -->
      <div class="col-md-4 col-sm-6">
        <div class="single-post">
          <div class="single-post-thumb">
            <img src="assets/img/b1.jpg" alt=""/>
          </div>
          <div class="single-post-text">
            <div class="post-meta-info">
              <h2>{{ content['bringing-sea-zimbabwe'] ? this.content['bringing-sea-zimbabwe'].title[lang] : '' }}</h2>
            </div>
            <div [innerHTML]="content['bringing-sea-zimbabwe'] ? this.content['bringing-sea-zimbabwe'].description[lang] : ''">
            </div>
          </div>
        </div>
      </div>
      <!-- Single Blog Item End -->
      <!-- Single Blog Item Start -->
      <div class="col-md-4 col-sm-6">
        <div class="single-post">
          <div class="single-post-thumb">
            <img src="assets/img/b2.jpg" alt=""/>
          </div>
          <div class="single-post-text">
            <div class="post-meta-info">
              <h2>{{ content['custom-made'] ? this.content['custom-made'].title[lang] : '' }}</h2>
            </div>
            <div [innerHTML]="content['custom-made'] ? this.content['custom-made'].description[lang] : ''">
            </div>
          </div>
        </div>
      </div>
      <!-- Single Blog Item End -->
      <!-- Single Blog Item Start -->
      <div class="col-md-4 col-sm-6">
        <div class="single-post">
          <div class="single-post-thumb">
            <img src="assets/img/b3.jpg" alt=""/>
          </div>
          <div class="single-post-text">
            <div class="post-meta-info">
              <h2>{{ content['logistics-specialists'] ? this.content['logistics-specialists'].title[lang] : '' }}</h2>
            </div>
            <div [innerHTML]="content['logistics-specialists'] ? this.content['logistics-specialists'].description[lang] : ''">
            </div>
          </div>
        </div>
      </div>
      <!-- Single Blog Item End -->
    </div>
  </div>
</div>
<!-- Blog Section End -->
<!-- Service Section Start -->
<div class="service-sec pb-100">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>{{ content['services'] ? this.content['services'].title[lang] : '' }}</h1>
        <div class="service-sidebar">
          <div class="service-cat">
            <ul *ngIf="content['services']">
              <li *ngFor="let line of splitContent(this.content['services'].description[lang])">
                <a>{{ line }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Service Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
