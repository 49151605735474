import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '../../services/api.service';
import {Router} from "@angular/router";
import { BehaviorSubject } from 'rxjs';

declare const $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    lang = 'en';
    isHomeActive = true;
    isAboutActive = false;
    isFacilitiesActive = false;
    isTariffsActive = false;
    isNewsActive = false;
    isPubsActive = false;
    isMagsActive = false;
    isComplaint = false;
    complaintPDF$ = new BehaviorSubject(null);

    constructor(
        private translate: TranslateService,
        private api: ApiService,
        private router: Router
    ) {
        this.lang = this.api.loadPrefLang();
    }

    setAboutActive() {
        this.isHomeActive = false;
        this.isAboutActive = true;
        this.isFacilitiesActive = false;
        this.isTariffsActive = false;
        this.isNewsActive = false;
        this.isPubsActive = false;
        this.isMagsActive = false;
        this.isComplaint = false;
    }

    setFacilitiesActive() {
        this.isHomeActive = false;
        this.isAboutActive = false;
        this.isFacilitiesActive = true;
        this.isTariffsActive = false;
        this.isNewsActive = false;
        this.isPubsActive = false;
        this.isMagsActive = false;
        this.isComplaint = false;
    }

    setTariffsActive() {
        this.isHomeActive = false;
        this.isAboutActive = false;
        this.isFacilitiesActive = false;
        this.isTariffsActive = true;
        this.isNewsActive = false;
        this.isPubsActive = false;
        this.isMagsActive = false;
        this.isComplaint = false;
    }

    setNewsActive() {
        this.isHomeActive = false;
        this.isAboutActive = false;
        this.isFacilitiesActive = false;
        this.isTariffsActive = false;
        this.isNewsActive = true;
        this.isPubsActive = false;
        this.isMagsActive = false;
        this.isComplaint = false;
    }

    setPubsActive() {
        this.isHomeActive = false;
        this.isAboutActive = false;
        this.isFacilitiesActive = false;
        this.isTariffsActive = false;
        this.isNewsActive = false;
        this.isPubsActive = true;
        this.isMagsActive = false;
        this.isComplaint = false;
    }

    setMagsActive() {
        this.isHomeActive = false;
        this.isAboutActive = false;
        this.isFacilitiesActive = false;
        this.isTariffsActive = false;
        this.isNewsActive = false;
        this.isPubsActive = false;
        this.isMagsActive = true;
        this.isComplaint = false;
    }

    setComplaintActive() {
        this.isHomeActive = false;
        this.isAboutActive = false;
        this.isFacilitiesActive = false;
        this.isTariffsActive = false;
        this.isNewsActive = false;
        this.isPubsActive = false;
        this.isMagsActive = false;
        this.isComplaint = true;
    }

    checkUrl(url: string) {
        if (url.indexOf('about') >= 0 ||
            url.indexOf('safety') >= 0 ||
            url.indexOf('projects') >= 0 ||
            url.indexOf('social-responsibilities') >= 0 ||
            url.indexOf('shipping-lines') >= 0) {
            this.setAboutActive();
            return;
        } else if (url.indexOf('port/') >= 0) {
            this.setFacilitiesActive();
            return;
        } else if (url.indexOf('tariffs') >= 0) {
            this.setTariffsActive();
            return;
        } else if (url.indexOf('news') >= 0) {
            this.setNewsActive();
            return;
        } else if (url.indexOf('publications') >= 0) {
            this.setPubsActive();
            return;
        } else if (url.indexOf('magazine') >= 0) {
            this.setMagsActive();
            return;
        } else if (url.indexOf('complaint') >= 0) {
            this.setComplaintActive();
            return;
        }
    }

    ngOnInit() {

        setTimeout(() => {
            $('#main-menu').meanmenu({
                meanScreenWidth: '767',
                meanMenuContainer: '.mobile-nav-menu',
            });
        }, 1000);

        this.router.events.subscribe((val: any) => {
            if (val.hasOwnProperty('url')) {
                this.checkUrl(val.url);
            }
        });

        this.loadComplaintPDF();
    }

    setLang(lang: string) {
        this.translate.setDefaultLang(lang);
        this.api.setPrefLang(lang);
        this.lang = lang;
        window.location.reload();
    }

    loadComplaintPDF(): void {
        this.api.loadDocuments('menu', 'complaint', this.lang).subscribe((data: any) => {
            const keys = Object.keys(data);
            if (keys.length > 0) {
                console.log(data[keys[0]].docs[0]);
                this.complaintPDF$.next(data[keys[0]].docs[0])
            }
        });
    }

}
