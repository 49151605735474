import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  images= [
    'assets/img/slider/slide1.jpg',
    'assets/img/slider/slide2.jpg',
    'assets/img/slider/slide3.jpg'
  ];
  $popup = new BehaviorSubject(false);
  popup: any = null;

  constructor(
    private api: ApiService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      $('#callToYoutube').fadeIn(500);
    }, 5000);

    $('#callToYoutube .fa-close, .open').click(() => {
      $('#callToYoutube').hide();
    });

    setTimeout(() => {
      this.loadPopups();
    }, 2000);
  }

  loadPopups() {
    this.api.loadPopups(this.translate.getDefaultLang()).subscribe((r: any[]) => {
      if (r.length > 0) {
        this.popup = r[0]
        this.$popup.next(true);
        setTimeout(() => {
          $('#popups').fadeIn(500);
          $('#popups .fa-close, .open').click(() => {
            $('#popups').hide();
          });
        }, 100);
      }
    })
  }

}
