<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Projects</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a translate>Projects</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Section Start -->
<div class="project-page-sec pt-100 pb-100">
  <div class="container" *ngFor="let proj of projects">
    <!-- Section Title Start -->
    <div class="row">
      <div class="col-md-12">
        <div class="sec-title">
          <h1>{{ proj.title[lang] }}</h1>
          <p class="text-justify" [innerHtml]="proj.description[lang]"></p>
        </div>
      </div>
      <!-- Single project Inner Start -->
      <div class="col-md-12 col-xs-12" *ngFor="let item of proj.photos">
        <div class="sec-title">
          <div class="single-project-inner">
            <div class="project-thumb">
              <img [src]="host + '/storage/projects/' + item.photo" alt=""/>
              <div class="project-thumb-overlay">
                <div class="project-icon">
                  <a [href]="host + '/storage/projects/' + item.photo" class="fancybox" rel="gallery" [title]="proj.title[lang]"><i class="fa fa-external-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Single project Inner End -->
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12" *ngIf="pages > 0">
        <div class="pagination custom-pagination text-center">
          <ul class="pagination custom-pagination">
            <li><a (click)="loadProjects(page - 1)"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
            <li [ngClass]="{'active': page === (p+1)}" *ngFor="let p of getArrayFrom(pages)"><a (click)="loadProjects(p + 1)">{{ p + 1 }}</a></li>
            <li><a (click)="loadProjects(page + 1)"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
