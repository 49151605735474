import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

declare const $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  news = [];
  pages = 0;
  page = 1;
  have_link = false;

  constructor(private api: ApiService) {}

  ngOnInit() {
    // setTimeout(() => {
    //   $('#main-menu > ul > li.active').removeClass('active');
    //   $($('#main-menu > ul > li')[2]).addClass('active');
    // }, 500);

    this.loadPage(this.page);
  }

  getArrayFrom(n: number) {
    return Array.from(Array(n).keys());
  }

  loadPage(p: number) {
    this.have_link = false;
    this.api.getNews(p).subscribe((result: any) => {
      this.news = result.data;
      this.pages = result.last_page;
      this.page = p;
      result.data.forEach((n) => {
        if (n.link !== '') {
          this.have_link = true;
          return;
        }
      });
    });
  }
}
