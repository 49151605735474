import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

declare const $: any;

@Component({
  selector: 'app-mutary',
  templateUrl: './mutary.component.html',
  styleUrls: ['./mutary.component.scss']
})
export class MutaryComponent implements OnInit {
  lang = 'en'
  content = {}

  constructor(private api: ApiService) {
    this.lang = this.api.loadPrefLang()
    this.loadContent('mutary', 'bringing-sea-zimbabwe')
    this.loadContent('mutary', 'custom-made')
    this.loadContent('mutary', 'logistics-specialists')
    this.loadContent('mutary', 'services')
  }

  ngOnInit() {
    setTimeout(() => {
      $('#main-menu > ul > li.active').removeClass('active');
      $($('#main-menu > ul > li')[3]).addClass('active');
    }, 500);
  }

  loadContent(area: string, subarea: string) {
    this.api.loadContent(area, subarea).subscribe((data: any) => {
      this.content[subarea] = {
        title: data.title,
        description: data.description,
        description2: data.description2
      }
    })
  }

  splitContent(text) {
    return text.replace(/<[^>]*>?/gm, '').split(';')
  }
}
