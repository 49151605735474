import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

declare const $: any;

@Component({
  selector: 'app-complaint-pdf',
  templateUrl: './complaint-pdf.component.html',
  styleUrls: ['./complaint-pdf.component.scss']
})
export class ComplaintPDFComponent implements OnInit {
  host = '';
  lang = 'en';
  document = null;
  photo = '';
  document$ = new BehaviorSubject(false);

  url: SafeUrl = '';


  constructor(
      private api: ApiService,
      private sanitizer: DomSanitizer
  ) {
    this.lang = this.api.loadPrefLang();
    this.host = this.api.getHost();
    this.loadFile();
  }

  ngOnInit() {
  }

  loadFile() {
    this.api.loadDocuments('menu', 'complaint', this.lang).subscribe((data: any) => {
      const keys = Object.keys(data);
      this.document = data[keys[0]].docs[0];
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.host + '/documents/' + this.document.filename);
      this.document$.next(true);
    });
  }

}
