<!-- Slider Section Start -->
<div class="slider">
  <div class="all-slide owl-item">
    <!-- Slider Single Item Start -->
    <div class="single-slide" *ngFor="let item of imgs" [ngStyle]="{'background-image': 'url(' + item + ')' }">
      <div class="slider-overlay"></div>
      <div class="slider-wrapper">
        <div class="slider-text">
          <div class="slider-caption">
            <h1>{{ slogan }}</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Slider Single Item End -->
  </div>
</div>
<!-- Slider Section End -->
