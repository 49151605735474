import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';

declare var $: any;

@Component({
  selector: 'app-home-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsHomeComponent implements OnInit {
  news = []
  lang = 'en'
  host = ''
  highlight = null
  @Input() HideContent = false

  constructor(private api: ApiService) {
    this.lang = this.api.loadPrefLang()
    this.host = this.api.getHost()
  }

  ngOnInit() {
    this.api.latestNews().subscribe((data: any) => {
      this.news = data
    })
    this.api.highlightNews().subscribe((data: any) => {
      if (data.length > 0) {
        this.highlight = data[0]
        setTimeout(() => {
          $('.highlight').fadeIn(800);
        }, 5000)
      }
    })
    $('.close_high').click(function () {
      $('.highlight').fadeOut(300);
    })
  }

}
