<div class="single-post" (click)="goToSocialDetails()">
  <div class="single-post-thumb">
    <img *ngIf="item.photos.length > 0" [src]="host + '/storage/social_projects/' + item.photos[0].photo" alt=""/>
    <div class="single-post-thumb-overlay">
      <div class="post-meta">
        <span class="post-date">{{ item.created_at | amLocale:lang | amDateFormat:'DD' }}</span>
        <span class="post-month">{{ item.created_at | amLocale:lang | amDateFormat:'MMM' }}</span>
      </div>
    </div>
  </div>
  <div class="single-post-text">
    <div class="post-meta-info">
      <h2><a>{{ item.title[lang] }}</a></h2>
    </div>
    <p *ngIf="!HideContent" [innerHtml]="item.description[lang]"></p>
  </div>
</div>
