<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>Publications</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a translate>Publications</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!--project Section Start -->
<div class="project-page-sec pt-10 pb-100">
  <div class="container">
    <!-- Section Title End -->
    <div class="row">
      <div class="col-md-4" *ngFor="let doc of documents">
        <div class="service-sidebar">
          <div class="service-cat">
            <ul>
              <li><a [href]="host + '/publications/' + doc.filename" target="_blank">{{ doc.name }}</a></li>
            </ul>
            <div class="cover">
              <img [src]="host + '/publications/thumbs/' + doc.photo" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Project Section End -->
<!-- Call To Action Section Start -->
<app-call-to-action></app-call-to-action>
<!-- Call To Action Section Start -->
