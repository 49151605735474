import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

declare const $: any

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {
  documents = []
  lang = 'en'
  host = ''

  constructor(private api: ApiService) {
    this.lang = this.api.loadPrefLang()
    this.host = this.api.getHost()
    this.loadContent();
  }

  ngOnInit() {
  }

  loadContent() {
    this.api.loadPublications(this.lang).subscribe((data: any) => {
      this.documents = data
    })
  }

}
