<!-- Page Heading Section Start -->
<div class="pagehding-sec">
  <div class="page-hd-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="page-heading">
          <h1 translate>About Us</h1>
        </div>
        <div class="breadcrumb-list">
          <ul>
            <li><a [routerLink]="['/']" translate>Home</a></li>
            <li><a translate>About Us</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Heading Section End -->
<!-- Why Like Us Section Start -->
<div class="why-like-sec">
  <div class="container">
    <!-- Section Title Start -->
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-8 text-center">
        <img [src]="photo"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="sec-title">
          <h1>{{ content['who-we-are'] ? this.content['who-we-are'].title[lang] : '' }}</h1>
          <div class="border-bottom"></div>
        </div>
      </div>
    </div>
    <!-- Section Title End -->
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="why-like-item" [innerHTML]="content['who-we-are'] ? this.content['who-we-are'].description[lang] : ''">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Like Us Section End -->
<!-- Why Us Section Start -->
<div class="why-us-sec pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="why-us-box">
          <h1>{{ content['about-the-port'] ? this.content['about-the-port'].title[lang] : '' }}</h1>
          <div [innerHTML]="content['about-the-port'] ? this.content['about-the-port'].description[lang] : ''">
          </div>
          <div class="why-us-box-list">
            <div class="row" *ngIf="content['about-the-port']">
              <div class="col-md-12" *ngFor="let line of splitContent(this.content['about-the-port'].description2[lang])">
                <div class="why-us-box-item">
                  <div class="media">
                    <div class="media-left">
                      <div class="why-us-box-icon">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="media-body">
                      <div class="why-us-box-text">{{ line }};</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <!-- Why Us Single Inner  Start -->
          <div class="col-md-6 col-sm-6">
            <div class="why-us-item">
              <div class="why-us-item-img text-center">
                <img src="assets/img/icon/visao.svg" alt="" style="width:50%;"/>
              </div>
              <div class="why-us-item-desc">
                <h2>{{ content['our-vision'] ? this.content['our-vision'].title[lang] : '' }}</h2>
                <div [innerHTML]="content['our-vision'] ? this.content['our-vision'].description[lang] : ''">
                </div>
              </div>
            </div>
          </div>
          <!-- Why Us Single Inner  Start -->
          <!-- Why Us Single Inner  Start -->
          <div class="col-md-6 col-sm-6">
            <div class="why-us-item">
              <div class="why-us-item-img text-center">
                <img src="assets/img/icon/missao.svg" alt="" style="width:50%;"/>
              </div>
              <div class="why-us-item-desc">
                <h2>{{ content['our-mission'] ? this.content['our-mission'].title[lang] : '' }}</h2>
                <div [innerHTML]="content['our-mission'] ? this.content['our-mission'].description[lang] : ''">
                </div>
              </div>
            </div>
          </div>
          <!-- Why Us Single Inner  Start -->
          <!-- Why Us Single Inner  Start -->
          <div class="col-md-12 col-sm-12">
            <div class="why-us-item">
              <div class="why-us-item-img text-center">
                <img src="assets/img/icon/valores.svg" alt="" style="width:25%;"/>
              </div>
              <div class="why-us-item-desc">
                <h2>{{ content['our-values'] ? this.content['our-values'].title[lang] : '' }}</h2>
                <div [innerHTML]="content['our-values'] ? this.content['our-values'].description[lang] : ''">
                </div>
              </div>
            </div>
          </div>
          <!-- Why Us Single Inner  Start -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Us Section End -->
<app-call-to-action></app-call-to-action>
